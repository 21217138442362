import { navigate } from 'gatsby-plugin-intl';

export default () => {
    if (typeof window !== 'undefined') {
        const userDomain = localStorage.getItem('domain');

        if (userDomain) {
            localStorage.removeItem('tenant_id');
            localStorage.removeItem('user_email');
            localStorage.removeItem('domain');
            localStorage.removeItem('access_token');

            window.location.replace(`http://${userDomain}.awasul1.com/logout`);
        } else {
            return navigate('/');
        }
    }
    return null;
};
